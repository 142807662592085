import React from "react"
import {Container} from "react-bootstrap"
import SEO from "../components/seo"
import Layout from "../components/layout";
import {graphql} from "gatsby";

const PrivacyPolicy = ({data}) => {

    return (
        <Layout pageInfo={{pageName: "index"}}>
            <SEO title={data?.policy?.nodes[0]?.metaForPage?.title ?? "Privacy Policy"} description={data?.policy?.nodes[0]?.metaForPage?.description} />

            <Container className="terms-and-conditions pt-6 pb-6">
                {data?.policy?.nodes?.map((item) => (
                    <div dangerouslySetInnerHTML={{ __html: item.content}}></div>
                ))}
            </Container>

        </Layout>
    )
}

export const data = graphql`
    query Policy{
        policy: allWpPage(filter: {
            slug: {
                eq: "privacy-policy"
            }
        }
        ){
            nodes {
                content
                metaForPage {
                    title
                    description
                }
            }
        }
    }
`
export default PrivacyPolicy
